'use client'

import { borderAnimation } from '@/core/animations'
import Box from '@mui/material/Box'
import Button, { ButtonProps } from '@mui/material/Button'
import React from 'react'

export const RcButton = (props: ButtonProps & { highlight?: boolean }) => {
  return (
    <BorderAnimator highlight={props.highlight && !props.disabled}>
      <Button {...props} />
    </BorderAnimator>
  )
}

export const BorderAnimator = ({
  children,
  highlight
}: {
  children: React.ReactNode
  highlight?: boolean
}) => {
  if (!highlight) return children
  return (
    <Box position={'relative'} p={0.5}>
      <Box
        sx={theme => ({
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          p: 0.5,
          borderRadius: 15,
          ...borderAnimation()
        })}
      />
      {children}
    </Box>
  )
}
